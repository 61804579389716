import { createModel } from '@rematch/core';

import type { RootModel } from '.';

export interface AuditProductsState {
  availability: {
    options: string[];
    selected: string | null;
  };
  serviceMode: {
    options: string[];
    selected: string | null;
  };
  channel: {
    options: string[];
    selected: string | null;
  };
  type: {
    options: string[];
    selected: string[];
  };
  section: {
    options: string[];
    selected: string[];
  };
  restaurant: {
    options: string[];
    selected: string[];
  };
  searchTerm: {
    query: string;
  };
}

export const auditProducts = createModel<RootModel>()({
  state: {
    availability: {
      options: ['Available', 'Unavailable'],
      selected: null,
    },
    serviceMode: {
      options: ['Delivery', 'Eat in', 'Takeaway'],
      selected: null,
    },
    channel: {
      options: ['App/Web', 'In-store'],
      selected: null,
    },
    type: {
      options: ['Item', 'Combo'],
      selected: [],
    },
    section: {
      options: ['Section 1', 'Section 2'],
      selected: [],
    },
    restaurant: {
      options: ['Restaurant 1', 'Restaurant 2'],
      selected: [],
    },
    searchTerm: {
      query: '',
    },
  } as AuditProductsState,

  reducers: {
    setAvailability(state, payload: string | null) {
      return { ...state, availability: { ...state.availability, selected: payload } };
    },
    setServiceMode(state, payload: string | null) {
      return { ...state, serviceMode: { ...state.serviceMode, selected: payload } };
    },
    setChannel(state, payload: string | null) {
      return { ...state, channel: { ...state.channel, selected: payload } };
    },
    setType(state, payload: string[]) {
      return { ...state, type: { ...state.type, selected: payload } };
    },
    setSection(state, payload: string[]) {
      return { ...state, section: { ...state.section, selected: payload } };
    },
    setRestaurant(state, payload: string[]) {
      return { ...state, restaurant: { ...state.restaurant, selected: payload } };
    },
    setRestaurantOptions(state, payload: string[]) {
      return { ...state, restaurant: { ...state.restaurant, options: payload } };
    },
    setSearchTerm(state, payload: string) {
      return { ...state, searchTerm: { query: payload } };
    },
  },

  selectors: (slice, createSelector) => ({
    getAvailabilityOptions() {
      return slice(state => state.availability.options);
    },
    getServiceModeOptions() {
      return slice(state => state.serviceMode.options);
    },
    getChannelOptions() {
      return slice(state => state.channel.options);
    },
    getTypeOptions() {
      return slice(state => state.type.options);
    },
    getSectionOptions() {
      return slice(state => state.section.options);
    },
    getRestaurantOptions() {
      return slice(state => state.restaurant.options);
    },
    getSelectedAvailability() {
      return slice(state => state.availability.selected);
    },
    getSelectedServiceMode() {
      return slice(state => state.serviceMode.selected);
    },
    getSelectedChannel() {
      return slice(state => state.channel.selected);
    },
    getSelectedType() {
      return slice(state => state.type.selected);
    },
    getSelectedSection() {
      return slice(state => state.section.selected);
    },
    getSelectedRestaurant() {
      return slice(state => state.restaurant.selected);
    },
    getSearchTerm() {
      return slice(state => state.searchTerm.query);
    },
  }),
});
